
// ### TODO
// Edge/IE currently has issue displaying with native ruby-position,
// which will be fixed through the next Han.css version.
//
  h-ru
    display: ruby

    &[order='0']
      -webkit-ruby-position: over
      ruby-position: over
    &[order='1'],
    &.rightangle
      -webkit-ruby-position: under
      ruby-position: under
    rt
      display: ruby-text

ruby.zhuyin,
ruby.mps,
h-ruby.zhuyin
  h-zhuyin
    position: relative
    letter-spacing: 0
  h-diao
    // positioning
    position: absolute
    right: -.9em
    bottom: .5em
    // box-model
    display: block
    // typography
    font-size: 1.5em

    h-char
      -webkit-writing-mode: horizontal-tb
      writing-mode:         horizontal-tb
  [diao='˙'] h-diao
    // positioning
    top: -.3em
    right: auto
    bottom: auto
    left: 0
    // typography
    font-size: 1em
  [diao^='ㆴ'],
  [diao^='ㆵ'],
  [diao^='ㆶ'],
  [diao^='ㆷ'],
  [diao='󳆴'],
  [diao='󳆵'],
  [diao='󳆶'],
  [diao='󳆷']
    h-diao
      // positioning
      right: -1em
      bottom: -.125em
      // typography
      font-size: 1em

//.no-ruby-display (L62-107)
h-ru[annotation]
  // positioning
  position: relative
  // box-model
  display: inline-table
  border-collapse: collapse
  border-spacing: 0
  // typography
  line-height: 1.1
  text-align: center
  vertical-align: 1em

  > h-ru[annotation]
    vertical-align: -.1em
  > h-ru,
  > rb,
  > rt
    // typography
    line-height: 1
    text-align: center
  > rt
    // box-model
    display: table-header-group
    height: 1em
    // typography
    font-size: .5em
    white-space: nowrap
    word-break: normal

    &:before,
    &:after
      content: '\2006'

h-ru[order='0'] > rt,
h-ruby[rightangle][doubleline] h-ru[order='0'] > rt
  display: table-header-group
h-ru[order='1'] > rt,
h-ruby[rightangle] h-ru[order='0'] > rt,
h-ruby[rightangle][doubleline] h-ru[order='1'] > rt
  display: table-footer-group
h-ru[order='0'] > h-ru[order='1']
  vertical-align: .15em
h-ruby[rightangle][doubleline] h-ru[order='0'] rt
  line-height: 1.5
h-ruby[rightangle][doubleline] h-ru[annotation]
  vertical-align: .5em

//  Zhuyin
// --------
%han-zhuyin
  h-zhuyin
    +han-typo-reset
    // positioning
    position: relative
    // box-model
    display: inline-block
    height: 1em
    width: .4em
    vertical-align: text-top
  h-zhuyin > *
    // box-model
    +han-scale( $HAN-ZHUYIN-SIZE )
    display: inline-block
  h-yin
    // positioning
    position: absolute
    left: 0
    // box-model
    height: 1em
    vertical-align: top
    line-height: 1
  h-diao
    // positioning
    position: absolute
    bottom: 0
    right: -.9em
    // typography
    line-height: 1
  h-yin:empty,
  h-diao:empty
    display: none

  [length='0']
    margin-right: 0
    h-zhuyin
      display: none
  [length='1']
    h-yin
      top: .3em
    h-diao
      bottom: 0
  [length='2']
    h-yin
      top: .05em
    h-diao
      bottom: -.3em
  [length='3']
    h-yin
      top: -.05em
      line-height: .85
    h-diao
      bottom: -.35em
  [diao='˙']
    h-diao
      top: 0
      right: auto
      bottom: auto
      left: .06em
    [length='1'] h-diao
      top: .15em
    [length='2'] h-diao
      top: -.05em
    [length='3'] h-diao
      top: -.2em
  [diao='˪'],
  [diao='˫']
    h-diao
      +han-scale( .6 )
  [diao^='ㆴ'],
  [diao^='ㆵ'],
  [diao^='ㆶ'],
  [diao^='ㆷ'],
  [diao='󳆴'],
  [diao='󳆵'],
  [diao='󳆶'],
  [diao='󳆷']
    h-diao
      bottom: -.6em
      margin-right: .3em

[zhuyin]
  @extend %han-zhuyin
  // positioning
  margin-right: .2em
  // box-model
  display: inline-block
  // typography
  line-height: 1.8

//  Right-angle
// -------------
[rightangle]
  h-ru[annotation]
    vertical-align: 0
    line-height: 1
  rt:after
    content: ''
    display: inline-block
    width: 1.2em

h-ru h-ru[zhuyin]
  // positioning
  margin-top: -.4em
  margin-bottom: -.2em
  // typography
  line-height: 1.8

