
/**
 * CJK Biaodian compression (CJK標點擠壓)
 */

h-char.bd-jiya.bd-open:before,
h-char.bd-jiya.bd-end:after,
h-cs,
h-cs[hidden]
  // box
  display: none
  visibility: hidden
  // typography
  content: ' '
  font: .825em Courier
  letter-spacing: 0
  white-space: normal

h-cs.jinze-outer,
h-cs.jinze-outer[hidden]
  display: inline

// * Handle line start/end Biaodian (行首行尾標點擠壓)
// *

h-char.bd-jiya
  &.bd-open > h-inner
    margin-left: -.5em

  &.bd-close,
  &.bd-cop,
  &[unicode='ff0e']
    > h-inner
      letter-spacing: -.5em

  &.bd-open:before,
  &.bd-close:after,
  &.bd-cop:after,
  &[unicode='ff0e']:after
    display: inline

  &.bd-cop
    &:lang(zh-Hant),
    &:lang(zh-TW),
    &:lang(zh-HK)
      &:after
        display: none
      > h-inner
        letter-spacing: inherit

// * Handle consecutive Biaodian (連續標點擠壓)
// *

h-char.bd-consecutive
  // * Basic situation
  // *
  &.bd-end:not(.end-portion):after,
  &.bd-open[prev='bd-open']:before
    display: none

  @at-root h-cs.jiya-outer.bd-end:not(.end-portion)
    display: none

  @at-root h-cs.jiya-outer.bd-end[next='bd-open']
    display: inline

  &.bd-open[prev*='bd-cop']
    &:lang(zh-Hant):before,
    &:lang(zh-TW):before,
    &:lang(zh-HK):before
      display: none

  @at-root h-cs.jiya-outer[prev*='bd-cop']
    display: none

  @at-root h-cs.jiya-outer.bd-end
    &:lang(zh-Hant),
    &:lang(zh-TW),
    &:lang(zh-HK)
      display: none

  // * Handle ‘「漢」·「字」’ situation
  // *
  &[unicode='b7'],
  &[unicode='30fb']
    &:not(.end-portion)
      letter-spacing: -.5em

  // * Handle ‘……「漢字’ situation
  // *
  &.bd-liga:not(.end-portion)
    margin-right: -.25em

