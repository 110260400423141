
/**
 * Basic correction to CJK punctuation
 * ‘Biaodian’(zh) and ‘Yakumono’(ja)
 */

// * 1. Recommended (推薦)
// * 2. CNS (適用繁體的舊字形或國字標準字形字體)
// * 3. GB (中國國標)

/* Fullwidth full stop (．) */
// 1
@font-face {
  font-family: 'Biaodian Sans';
  src:
    local('Hiragino Sans GB'),
    local('Heiti SC'),
    local(STHeiti),
    local('MS Gothic'),
    local(SimSun)
  ;
  unicode-range: U+FF0E;
}

@font-face {
  font-family: 'Biaodian Serif';
  src:
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Songti SC'),
    local(STSong),
    local(SimSun)
  ;
  unicode-range: U+FF0E;
}

@font-face {
  font-family: 'Biaodian Pro Sans';
  src:
    local('Hiragino Sans GB'),
    local('Heiti SC'),
    local(STHeiti),
    local('MS Gothic'),
    local(SimSun)
  ;
  unicode-range: U+FF0E;
}

@font-face {
  font-family: 'Biaodian Pro Serif';
  src:
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Songti SC'),
    local(STSong),
    local(SimSun)
  ;
  unicode-range: U+FF0E;
}

// 2
@font-face {
  font-family: 'Biaodian Pro Sans CNS';
  src:
    local('Hiragino Sans GB'),
    local('Heiti SC'),
    local(STHeiti),
    local('MS Gothic'),
    local(SimSun)
  ;
  unicode-range: U+FF0E;
}

@font-face {
  font-family: 'Biaodian Pro Serif CNS';
  src:
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Songti SC'),
    local(STSong),
    local(SimSun)
  ;
  unicode-range: U+FF0E;
}

// 3
@font-face {
  font-family: 'Biaodian Pro Sans GB';
  src:
    local('Hiragino Sans GB'),
    local('Heiti SC'),
    local(STHeiti),
    local('MS Gothic'),
    local(SimSun)
  ;
  unicode-range: U+FF0E;
}

@font-face {
  font-family: 'Biaodian Pro Serif GB';
  src:
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Songti SC'),
    local(STSong),
    local(SimSun)
  ;
  unicode-range: U+FF0E;
}

/* Middle dot (·) */
// 1
@font-face {
  font-family: 'Biaodian Sans';
  src:
    local('Hiragino Sans GB'),
    local('Heiti SC'),
    local(STHeiti),
    local(SimSun)
  ;
  unicode-range: U+00B7;
}

@font-face {
  font-family: 'Biaodian Serif';
  src:
    local('Songti SC'),
    local(STSong),
    local('Heiti SC'),
    local(SimSun)
  ;
  unicode-range: U+00B7;
}

@font-face {
  font-family: 'Biaodian Pro Sans';
  src:
    local('Hiragino Sans GB'),
    local('Heiti SC'),
    local(STHeiti),
    local(SimSun)
  ;
  unicode-range: U+00B7;
}

@font-face {
  font-family: 'Biaodian Pro Serif';
  src:
    local('Songti SC'),
    local(STSong),
    local('Heiti SC'),
    local(SimSun)
  ;
  unicode-range: U+00B7;
}

// 2
@font-face {
  font-family: 'Biaodian Pro Sans CNS';
  src:
    local('Hiragino Sans GB'),
    local('Heiti SC'),
    local(STHeiti),
    local(SimSun)
  ;
  unicode-range: U+00B7;
}

@font-face {
  font-family: 'Biaodian Pro Serif CNS';
  src:
    local('Songti SC'),
    local(STSong),
    local('Heiti SC'),
    local(SimSun)
  ;
  unicode-range: U+00B7;
}

// 3
@font-face {
  font-family: 'Biaodian Pro Sans GB';
  src:
    local('Hiragino Sans GB'),
    local('Heiti SC'),
    local(STHeiti),
    local(SimSun)
  ;
  unicode-range: U+00B7;
}

@font-face {
  font-family: 'Biaodian Pro Serif GB';
  src:
    local('Songti SC'),
    local(STSong),
    local('Heiti SC'),
    local(SimSun)
  ;
  unicode-range: U+00B7;
}

/* Em dash (——) */
// 1
@font-face {
  font-family: 'Biaodian Sans';
  src:
    $han-webfont,
    local('Hiragino Sans GB'),
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local('Microsoft Yahei'),
    local(SimSun)
  ;
  unicode-range: U+2014;
}

@font-face {
  font-family: 'Biaodian Serif';
  src:
    $han-webfont,
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Songti SC'),
    local(STSong),
    local('Microsoft Yahei'),
    local(SimSun)
  ;
  unicode-range: U+2014;
}

@font-face {
  font-family: 'Yakumono Sans';
  src:
    $han-webfont,
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local('Arial Unicode MS'),
    local('MS Gothic')
  ;
  unicode-range: U+2014;
}

@font-face {
  font-family: 'Yakumono Serif';
  src:
    $han-webfont,
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('MS Mincho'),
    local('Microsoft Yahei')
  ;
  unicode-range: U+2014;
}

@font-face {
  font-family: 'Biaodian Pro Sans';
  src:
    $han-webfont,
    local('Hiragino Sans GB'),
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local('Microsoft Yahei'),
    local(SimSun)
  ;
  unicode-range: U+2014;
}

@font-face {
  font-family: 'Biaodian Pro Serif';
  src:
    $han-webfont,
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Songti SC'), local(STSong),
    local('Microsoft Yahei'),
    local(SimSun)
  ;
  unicode-range: U+2014;
}

// 2
@font-face {
  font-family: 'Biaodian Pro Sans CNS';
  src:
    $han-webfont,
    local('Hiragino Sans GB'),
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local('Microsoft Yahei'),
    local(SimSun)
  ;
  unicode-range: U+2014;
}

@font-face {
  font-family: 'Biaodian Pro Serif CNS';
  src:
    $han-webfont,
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Songti SC'),
    local(STSong),
    local('Microsoft Yahei'),
    local(SimSun)
  ;
  unicode-range: U+2014;
}

// 3
@font-face {
  font-family: 'Biaodian Pro Sans GB';
  src:
    $han-webfont,
    local('Hiragino Sans GB'),
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local('Microsoft Yahei'),
    local(SimSun)
  ;
  unicode-range: U+2014;
}

@font-face {
  font-family: 'Biaodian Pro Serif GB';
  src:
    $han-webfont,
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Songti SC'),
    local(STSong),
    local('Microsoft Yahei'),
    local(SimSun)
  ;
  unicode-range: U+2014;
}

/* Ellipsis (……) */
// 1
@font-face {
  font-family: 'Biaodian Sans';
  src:
    $han-webfont,
    local('Hiragino Sans GB'),
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local(Meiryo),
    local('MS Gothic'),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range: U+2026;
}

@font-face {
  font-family: 'Biaodian Serif';
  src:
    $han-webfont,
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Songti SC'),
    local('MS Mincho'),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range: U+2026;
}

@font-face {
  font-family: 'Yakumono Sans';
  src:
    $han-webfont,
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local(Meiryo),
    local('MS Gothic')
  ;
  unicode-range: U+2026;
}

@font-face {
  font-family: 'Yakumono Serif';
  src:
    $han-webfont,
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('MS Mincho')
  ;
  unicode-range: U+2026;
}

@font-face {
  font-family: 'Biaodian Pro Sans';
  src:
    $han-webfont,
    local('Hiragino Sans GB'),
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range: U+2026;
}

@font-face {
  font-family: 'Biaodian Pro Serif';
  src:
    $han-webfont,
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Songti SC'),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range: U+2026;
}

// 2
@font-face {
  font-family: 'Biaodian Pro Sans CNS';
  src:
    $han-webfont,
    local('Hiragino Sans GB'),
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range: U+2026;
}

@font-face {
  font-family: 'Biaodian Pro Serif CNS';
  src:
    $han-webfont,
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Songti SC'),
    local(STSongti),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range: U+2026;
}

// 3
@font-face {
  font-family: 'Biaodian Pro Sans GB';
  src:
    $han-webfont,
    local('Hiragino Sans GB'),
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range: U+2026;
}

@font-face {
  font-family: 'Biaodian Pro Serif GB';
  src:
    $han-webfont,
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Songti SC'),
    local(STSongti),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range: U+2026;
}

/* Curve quotes (“‘’”, GB-only) */
// 3
@font-face {
  font-family: 'Biaodian Pro Sans GB';
  src:
    local('Hiragino Sans GB'),
    local('Heiti SC'),
    local(STHeiti),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range: U+201C-201D, U+2018-2019;
}

@font-face {
  font-family: 'Biaodian Pro Sans GB';
  font-weight: bold;
  src:
    local('Hiragino Sans GB'),
    local('Heiti SC'),
    local(STHeiti),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range: U+201C-201D, U+2018-2019;
}

@font-face {
  font-family: 'Biaodian Pro Serif GB';
  src:
    local('Lisong Pro'),
    local('Heiti SC'),
    local(STHeiti),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range: U+201C-201D, U+2018-2019;
}

@font-face {
  font-family: 'Biaodian Pro Serif GB';
  font-weight: bold;
  src:
    local('Lisong Pro'),
    local('Heiti SC'),
    local(STHeiti),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range: U+201C-201D, U+2018-2019;
}

/* Default emphasis mark (•) */
// 1
@font-face {
  font-family: 'Biaodian Sans';
  src:
    local(Georgia),
    local('Times New Roman'),
    local(Arial),
    local('Droid Sans Fallback')
  ;
  unicode-range: U+25CF;
}

@font-face {
  font-family: 'Biaodian Serif';
  src:
    local(Georgia),
    local('Times New Roman'),
    local(Arial),
    local('Droid Sans Fallback')
  ;
  unicode-range: U+25CF;
}

@font-face {
  font-family: 'Biaodian Pro Sans';
  src:
    local(Georgia),
    local('Times New Roman'),
    local(Arial),
    local('Droid Sans Fallback')
  ;
  unicode-range: U+25CF;
}

@font-face {
  font-family: 'Biaodian Pro Serif';
  src:
    local(Georgia),
    local('Times New Roman'),
    local(Arial),
    local('Droid Sans Fallback')
  ;
  unicode-range: U+25CF;
}

// 2
@font-face {
  font-family: 'Biaodian Pro Sans CNS';
  src:
    local(Georgia),
    local('Times New Roman'),
    local(Arial),
    local('Droid Sans Fallback')
  ;
  unicode-range: U+25CF;
}

@font-face {
  font-family: 'Biaodian Pro Serif CNS';
  src:
    local(Georgia),
    local('Times New Roman'),
    local(Arial),
    local('Droid Sans Fallback')
  ;
  unicode-range: U+25CF;
}

// 3
@font-face {
  font-family: 'Biaodian Pro Sans GB';
  src:
    local(Georgia),
    local('Times New Roman'),
    local(Arial),
    local('Droid Sans Fallback')
  ;
  unicode-range: U+25CF;
}

@font-face {
  font-family: 'Biaodian Pro Serif GB';
  src:
    local(Georgia),
    local('Times New Roman'),
    local(Arial),
    local('Droid Sans Fallback')
  ;
  unicode-range: U+25CF;
}

/**
 * Advanced correction to Chinese Biaodian
 */

// * a. Period, comma, paused comma (。，、)
// * b. Colon, semicolon, question, exclam-
// *	  ation marks (；：？！)
// * c. En dash (fullwidth hyphen-minus),
// *    division slash, back slash (－／＼)
// *

// 1
@font-face {
  font-family: 'Biaodian Pro Sans';
  src:
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local('MS Gothic')
  ;
  unicode-range:
    U+3002, U+FF0C, U+3001, // a
    U+FF1B, U+FF1A, U+FF1F, U+FF01, // b
    U+FF0D, U+FF0F, U+FF3C // c
  ;
}

@font-face {
  font-family: 'Biaodian Pro Serif';
  src:
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('MS Mincho')
  ;
  unicode-range:
    U+3002, U+FF0C, U+3001, // a
    U+FF1B, U+FF1A, U+FF1F, U+FF01, // b
    U+FF0D, U+FF0F, U+FF3C // c
  ;
}

// 2
@font-face {
  font-family: 'Biaodian Pro Sans CNS';
  src:
    local('Heiti TC'),
    local('Lihei Pro'),
    local('Microsoft Jhenghei'),
    local(PMingLiU)
  ;
  unicode-range:
    U+3002, U+FF0C, U+3001 // a
  ;
}

@font-face {
  font-family: 'Biaodian Pro Sans CNS';
  src:
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local('Heiti TC'), local('Lihei Pro'),
    local('Microsoft Jhenghei'),
    local(PMingLiU),
    local('MS Gothic')
  ;
  unicode-range:
    U+FF1B, U+FF1A, U+FF1F, U+FF01 // b
  ;
}

@font-face {
  font-family: 'Biaodian Pro Sans CNS';
  src:
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('MS Mincho')
  ;
  unicode-range:
    U+FF0D, U+FF0F, U+FF3C // c
  ;
}

@font-face {
  font-family: 'Biaodian Pro Serif CNS';
  src:
    local(STSongti-TC-Regular),
    local('Lisong Pro'),
    local('Heiti TC'),
    local(PMingLiU)
  ;
  unicode-range:
    U+3002, U+FF0C, U+3001 // a
  ;
}

@font-face {
  font-family: 'Biaodian Pro Serif CNS';
  src:
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local(PMingLiU),
    local('MS Mincho')
  ;
  unicode-range:
    U+FF1B, U+FF1A, U+FF1F, U+FF01, // b
    U+FF0D, U+FF0F, U+FF3C // c
  ;
}

// 3
@font-face {
  font-family: 'Biaodian Pro Sans GB';
  src:
    local('Hiragino Sans GB'),
    local('Heiti SC'),
    local(STHeiti),
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local(SimSun),
    local('MS Gothic')
  ;
  unicode-range:
    U+3002, U+FF0C, U+3001, // a
    U+FF1B, U+FF1A, U+FF1F, U+FF01, // b
    U+FF0D, U+FF0F, U+FF3C // c
  ;
}

@font-face {
  font-family: 'Biaodian Pro Serif GB';
  src:
    local('Songti SC'),
    local(STSongti),
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Hiragino Sans GB'),
    local('Heiti SC'),
    local(STHeiti),
    local(SimSun),
    local('MS Mincho')
  ;
  unicode-range:
    U+3002, U+FF0C, U+3001, // a
    U+FF1B, U+FF1A, U+FF1F, U+FF01 // b
  ;
}

@font-face {
  font-family: 'Biaodian Pro Serif GB';
  src:
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local(PMingLiU),
    local('MS Mincho')
  ;
  unicode-range:
    U+FF0D, U+FF0F, U+FF3C // c
  ;
}

// * a. Quotes (「『』」)
// * b. Brackets (（）《》〈〉〔〕)
// *

@font-face {
  font-family: 'Biaodian Pro Sans';
  src:
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local('Yu Gothic'),
    local(YuGothic),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range:
    U+300C-300F, // a
    U+300A-300B, U+3008-3009, U+FF08-FF09, U+3014-3015 // b
  ;
}

@font-face {
  font-family: 'Biaodian Pro Serif';
  src:
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Yu Mincho'),
    local(YuMincho),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range:
    U+300C-300F, // a
    U+300A-300B, U+3008-3009, U+FF08-FF09, U+3014-3015 // b
  ;
}

// 2
@font-face {
  font-family: 'Biaodian Pro Sans CNS';
  src:
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local('Yu Gothic'),
    local(YuGothic),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range:
    U+300C-300F, // a
    U+300A-300B, U+3008-3009, U+FF08-FF09, U+3014-3015 // b
  ;
}

@font-face {
  font-family: 'Biaodian Pro Serif CNS';
  src:
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Yu Mincho'),
    local(YuMincho),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range:
    U+300C-300F, // a
    U+300A-300B, U+3008-3009, U+FF08-FF09, U+3014-3015 // b
  ;
}

// 3
@font-face {
  font-family: 'Biaodian Pro Sans GB';
  src:
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro'),
    local('Yu Gothic'),
    local(YuGothic),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range:
    U+300C-300F, // a
    U+300A-300B, U+3008-3009, U+FF08-FF09, U+3014-3015 // b
  ;
}

@font-face {
  font-family: 'Biaodian Pro Serif GB';
  src:
    local('Hiragino Mincho ProN'),
    local('Hiragino Mincho Pro'),
    local('Yu Mincho'),
    local(YuMincho),
    local(SimSun),
    local(PMingLiU)
  ;
  unicode-range:
    U+300C-300F, // a
    U+300A-300B, U+3008-3009, U+FF08-FF09, U+3014-3015 // b
  ;
}

@font-face {
  font-family: 'Biaodian Basic';
  src: $han-webfont;
  unicode-range: U+2014, U+2026, U+00B7;
}
@font-face {
  font-family: 'Biaodian Basic';
  font-weight: bold;
  src: $han-webfont;
  unicode-range: U+2014, U+2026, U+00B7;
}

@font-face {
  font-family: 'Biaodian Sans';
  font-weight: bold;
  src: $han-webfont;
  unicode-range: U+2014, U+2026, U+00B7;
}

@font-face {
  font-family: 'Biaodian Pro Sans';
  font-weight: bold;
  src: $han-webfont;
  unicode-range: U+2014, U+2026, U+00B7;
}

@font-face {
  font-family: 'Biaodian Pro Sans';
  font-weight: bold;
  src: $han-webfont;
  unicode-range: U+2014, U+2026, U+00B7;
}

@font-face {
  font-family: 'Biaodian Pro Sans CNS';
  font-weight: bold;
  src: $han-webfont;
  unicode-range: U+2014, U+2026, U+00B7;
}

@font-face {
  font-family: 'Biaodian Pro Sans GB';
  font-weight: bold;
  src: $han-webfont;
  unicode-range: U+2014, U+2026, U+00B7;
}

@font-face {
  font-family: 'Biaodian Pro Serif';
  font-weight: bold;
  src: $han-webfont;
  unicode-range: U+2014, U+2026, U+00B7;
}

@font-face {
  font-family: 'Biaodian Pro Serif CNS';
  font-weight: bold;
  src: $han-webfont;
  unicode-range: U+2014, U+2026, U+00B7;
}

@font-face {
  font-family: 'Biaodian Pro Serif GB';
  font-weight: bold;
  src: $han-webfont;
  unicode-range: U+2014, U+2026, U+00B7;
}

