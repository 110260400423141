
// *!
// * **WARNING**
// * Unless you knew well enough, *nothing* below should
// * be modified!
// *

$HAN-ROOT:                   html
$HAN-LINE-HEIGHT:            1.3
$HAN-INDENT:                 2em
$HAN-JS-RENDERED-CLASS:      '.han-js-rendered'

$HAN-TEXT-EMPHASIS-SKIP:     true

$HAN-TEXT-EMPHASIS-SHAPE:    filled
$HAN-TEXT-EMPHASIS-MARK:     circle
$HAN-TEXT-EMPHASIS-POSI:     under
$HAN-TEXT-EMPHASIS-COLOR:    inherit

$HAN-TEXT-EMPHASIS-SHAPE-JA: filled
$HAN-TEXT-EMPHASIS-MARK-JA:  sesame
$HAN-TEXT-EMPHASIS-POSI-JA:  over
$HAN-TEXT-EMPHASIS-COLOR-JA: inherit

$HAN-ZHUYIN-SIZE:            .4

