
//  Root
// ------

#{$han-root}
  +han-typeface-by-lang( sans, true )
  @extend %han-ligature, %han-no-locl

[lang^='zh'],
[lang*='Hant'],
[lang='zh-TW'],
[lang='zh-HK']
  @extend %han-sans-hant, %han-no-locl

  .no-unicoderange &
    @extend %han-sans-hant-nu

[lang*='Hans'],
[lang='zh-CN']
  @extend %han-sans-hans, %han-no-locl

  .no-unicoderange &
    @extend %han-sans-hans-nu

[lang^='ja']
  @extend %han-sans-ja

  .no-unicoderange &
    @extend %han-sans-ja-nu

//  Biaodian correction (for non-unicode-range browsers)
// ---------------------
.no-unicoderange h-char
  &.bd-cop
    &:lang(zh-Hant),
    &:lang(zh-TW),
    &:lang(zh-HK)
      font-family: -apple-system, 'Han Heiti CNS'
  &.bd-liga,
  &[unicode='b7']
    @extend %han-ligature
    font-family: 'Biaodian Basic', 'Han Heiti'
  &[unicode='2018'],
  &[unicode='2019'],
  &[unicode='201c'],
  &[unicode='201d']
    &:lang(zh-Hans),
    &:lang(zh-CN)
      font-family: 'Han Heiti GB'

//  Sections & grouping content
// -----------------------------

// * Different typefaces in different situations.
// *
blockquote
  #{$han-article} &
    +han-typeface-by-lang( cursive )

  figure &
    #{$han-article} &,
    &
      +han-typeface-by-lang( serif )

//  Text-level semantics
// ----------------------

// * Importance in articles should be in sans-serif
// * (opinionated).
// *
#{$han-article} strong
  +han-typeface-by-lang( sans )
  @extend %han-no-locl

// * Correct Chinese monospace typeface issue in WebKit.
// *
code,
kbd,
samp,
pre
  +han-typeface-by-lang( mono )
  @extend %han-no-locl

// * Fonts for alternative voice and variable
// * in different situations.
i,
var
  +han-typeface-by-lang( cursive-italic )
  font-style: inherit

  // 1
  #{$han-article} blockquote &
    +han-typeface-by-lang( sans-italic )
    @extend %han-no-locl

// * 1. Use `Zhuyin Kaiti` for non-checked tones.
// * 2. Zhuyin: checked tone ligatures (方言音符號入聲連字).
// * 3. Romanisation: checked tone ligatures (拉丁字母入聲連字).
// *
ruby,
h-ruby
  h-zhuyin,
  h-diao
    // 1, 2
    @extend %han-ligature

    .no-unicoderange &
      font-family: 'Zhuyin Kaiti', cursive, serif

  h-diao
    font-family: 'Zhuyin Kaiti', cursive, serif

  &.romanization rt,
  [annotation] rt
    // 3
    @extend %han-ligature
    font-family: 'Romanization Sans', $han-sans, han-typeface( 'Han Heiti', $han-glyph-set-hant, $han-sans-zh ), sans-serif

