
@font-face
  src: $han-webfont
  font-family: 'Han Space'
  unicode-range: U+20

@if ( $han-hanging-hant == false )
  #{$_hanging-hant}
    h-cs,
    h-cs[hidden]
      // box-model
      display: inline
      visibility: inherit
      // typography
      font-family: inherit
      font-size: inherit

h-cs.hangable-outer,
h-cs.hangable-outer[hidden]
  display: inline
  font: 1em 'Han Space'

  @if ( $han-hanging-hant == false )
    &:lang(zh-Hant),
    &:lang(zh-TW),
    &:lang(zh-HK)
      display: none

#{$han-hanging-selector}
  // positioning
  position: relative

  &:after
    display: none !important
  &:before
    // box-model
    display: inline !important
    // typography
    content: ' '
    font: 1em 'Han Space', $han-mono
  > h-inner
    +han-typo-reset()
    // positioning
    position: absolute
    left: 0
    top: 0
    // box-model
    display: inline-block
    // typography
    line-height: 1.1

    ruby &,
    h-ru &
      position: relative

