.progress-bar {
  position: fixed;
  left: 0%;
  top: 0px;
  right: 0%;
  bottom: auto;
  z-index: 200;
  display: block;
  width: 100%;
  height: 0.25rem;
  justify-content: flex-start;
  background-color: #000;
  backface-visibility: visible;
  perspective-origin: 0% 50%;
  transform-origin: 0% 50%;
}
