
%han-serif {
  font-family:
    $han-serif,
    han-typeface( 'Han Songti', $_default-variant, $han-serif-zh ),
    cursive, serif
  ;
}

%han-serif-hant {
  font-family:
    han-biaodian( Serif, $han-biaodian-hant ),
    'Numeral LF Serif',
    $han-serif,
    'Zhuyin Kaiti',
    han-typeface( 'Han Songti', $han-glyph-set-hant, $han-serif-zh ),
    serif
  ;
}

%han-serif-hant-nu {
  font-family:
    'Numeral LF Serif',
    $han-serif,
    han-typeface( 'Han Songti', $han-glyph-set-hant, $han-serif-zh ),
    serif
  ;
}

%han-serif-hans {
  font-family:
    han-biaodian( Serif, $han-biaodian-hans ),
    'Numeral LF Serif',
    $han-serif,
    han-typeface( 'Han Songti', $han-glyph-set-hans, $han-serif-zh ),
    serif
  ;
}

%han-serif-hans-nu {
  font-family:
    'Numeral LF Serif',
    $han-serif,
    han-typeface( 'Han Songti', $han-glyph-set-hans, $han-serif-zh ),
    serif
  ;
}

%han-serif-ja {
  font-family:
    'Yakumono Serif',
    'Numeral LF Serif',
    $han-serif,
    serif
  ;
}

%han-serif-ja-nu {
  font-family: 'Numeral LF Serif', $han-serif, serif;
}

/**
 * Serif Italic
 */
%han-serif-italic {
  font-family:
    'Latin Italic Serif',
    $han-serif,
    han-typeface( 'Han Songti', $_default-variant, $han-serif-zh ),
    cursive, serif
  ;
}

%han-serif-italic-hant {
  font-family:
    han-biaodian( Serif, $han-biaodian-hant ),
    'Numeral LF Italic Serif',
    'Latin Italic Serif',
    $han-serif,
    'Zhuyin Kaiti',
    han-typeface( 'Han Songti', $han-glyph-set-hant, $han-serif-zh ),
    cursive, serif
  ;
}

%han-serif-italic-hant-nu {
  font-family:
    'Numeral LF Italic Serif',
    'Latin Italic Serif',
    $han-serif,
    han-typeface( 'Han Songti', $han-glyph-set-hant, $han-serif-zh ),
    cursive, serif
  ;
}

%han-serif-italic-hans {
  font-family:
    han-biaodian( Serif, $han-biaodian-hans ),
    'Numeral LF Italic Serif',
    'Latin Italic Serif',
    $han-serif,
    han-typeface( 'Han Songti', $han-glyph-set-hans, $han-serif-zh ),
    cursive, serif
  ;
}

%han-serif-italic-hans-nu {
  font-family:
    'Numeral LF Italic Serif',
    'Latin Italic Serif',
    $han-serif,
    han-typeface( 'Han Songti', $han-glyph-set-hans, $han-serif-zh ),
    cursive, serif
  ;
}

%han-serif-italic-ja {
  font-family:
    'Yakumono Serif',
    'Numeral LF Italic Serif',
    'Latin Italic Serif',
    $han-serif,
    cursive, serif
  ;
}

%han-serif-italic-ja-nu {
  font-family: 'Numeral LF Italic Serif', 'Latin Italic Serif', $han-serif, cursive, serif;
}

