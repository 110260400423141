@use "~inter-ui/variable" with (
  $inter-font-display: swap,
  $inter-font-path: '~inter-ui/Inter (web)'
);

@import "~katex/dist/katex.min.css";

$han-font-path: "../vendors/han-css/font/";
$han-version: "";
$han-sans: "Inter", "system-ui";
$han-serif: "Newsreader";
$han-sans-zh: "Source Han Sans", "Noto Han Sans", "思源黑体";
$han-serif-zh: "Source Han Serif", "思源宋体";
$han-mono: "SF Mono", "Menlo", "Consolas";
$han-mono-zh: "SF Mono", "Menlo", "Consolas";
@import "../vendors/han-css/index.scss";

:root {
  --sans-serif: "Inter", "system-ui", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  --serif: "Newsreader", Cambria, Cochin, Georgia, Times, "Times New Roman",
    "Source Han Serif", "思源宋体", "宋体", "STSong", "SimSong", "SimSun", serif;
  --monospaced: "SF Mono", "Menlo", "Consolas", "Courier New", Courier,
    monospace;
  font-family: var(--sans-serif);
  font-size: 18px;
  line-height: 1.8;
}

p,
ul,
li {
  font-family: var(--sans-serif);

  code {
    font-size: 16px;
  }
}

p {
  transition-property: background-color;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

// p:hover {
//   background-color: #ffffff;
// }

li {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

body {
  counter-reset: section;
}

rt {
  color: #3d3d3d;
  font-family: var(--sans-serif);
  font-weight: 600;
}

ruby {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: #838380;
  text-underline-offset: 0.15em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--serif);

  rt {
    font-family: var(--serif);
  }
}

h1 {
  font-weight: 400;
  color: #2b2b2b;
}

h2,
h3 {
  font-weight: normal;
}

h1 {
  counter-reset: subsection;
}

h2 {
  counter-reset: subsubsection;
}

h1:not([class="footnotes"]):before {
  counter-increment: section;
  content: counter(section) ". ";
}

h2:before {
  counter-increment: subsection;
  content: counter(section) "." counter(subsection) " ";
}

h3:before {
  counter-increment: subsubsection;
  content: counter(section) "." counter(subsection) "." counter(subsubsection)
    " ";
}

pre,
code {
  font-family: var(--monospaced);
  font-size: 14px;
}

a {
  color: inherit;
  // text-decoration: underline;
  // text-decoration-style: dotted;
  // text-underline-offset: 0.25em;
  border-bottom: 2px solid rgba(224, 86, 80, 0.5);
  margin: -1px -3px -2px -3px;
  padding: 1px  3px  2px  3px;

  transition-property: background, color, border-color, border-radius;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;

  &.footnote-ref {
    border-bottom: none;
  }

  &:hover {
    background: rgba(224, 86, 80, 0.75);
    color: rgb(250, 248, 244);
    border-bottom: none;
    border-radius: 5px;
  }

  &:visited {
    color: inherit;

    &:hover {
      color: rgb(250, 248, 244);
    }
  }
}

::selection {
  background: #ffff33;
}

div.title {
  font-family: var(--serif);
  font-size: 2.25em;
  font-weight: 300;
  border-bottom: 1px solid whitesmoke;
}

div.last-update-time {
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  font-family: var(--sans-serif);
  font-size: 0.875rem;
  font-weight: 500;
  color: #d4d4cf;
}

section {
  box-sizing: border-box;
  padding-left: 2rem;
  padding-right: 2rem;

  > main {
    padding-block-start: 1em;
    padding-block-end: 1em;
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
  }
}

img {
  max-width: 100%;
}

section.initial {
  padding-top: 4rem;
  color: #fefff8;
  background: #121212;

  ::selection {
    background: #bdba9f;
  }
}

section.h1,
section.footnotes {
  &:nth-of-type(2n) {
    background: #fefff8;
  }

  &:nth-of-type(2n + 1) {
    background: #f1f1ec;
  }
}

blockquote {
  box-sizing: border-box;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0.25em 1em 0.25em 1.5em;
  border-left: 0.25em solid #d8cf9d;
  background: #fff5d5;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

#decryption-form {
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  padding: 1.5rem 2rem;
  border-radius: 0.25rem;
  border: 1px solid #2b2b2b;

  p {
    margin-bottom: 0;
  }

  * + * {
    margin-top: 0.5rem;
  }

  #message {
    color: #6a737d;
    font-size: 0.875rem;
  }
}
