
/**
 * Zhuyin Kaiti
 */

@font-face {
  @include han-webfont;
  @include han-range-zhuyin;
  font-family: 'Zhuyin Kaiti';
}

/**
 * Zhuyin Heiti
 */

// * 1. Medial ‘yi’ (介音「ㄧ」)
// * 2. Tones (五聲調號)
// *

@font-face {
  @include han-range-zhuyin;
  font-family: 'Zhuyin Heiti';
  src:
    local('Hiragino Sans GB'),
    local('Heiti TC'),
    local('Microsoft Jhenghei'),
    $han-webfont
  ;
}

// 1
@font-face {
  font-family: 'Zhuyin Heiti';
  src:
    local('Heiti TC'),
    local('Microsoft Jhenghei'),
    $han-webfont
  ;
  unicode-range: U+3127;
}

// 2
@font-face {
  @include han-webfont;
  font-family: 'Zhuyin Heiti';
  unicode-range:
    U+02D9, U+02CA, U+02C5, U+02C7, U+02CB, U+02EA-02EB,
    U+31B4, U+31B5, U+31B6, U+31B7, U+0307, U+030D, U+0358,
    U+F31B4-F31B7,
    U+F0061, U+F0065, U+F0069, U+F006F, U+F0075
  ;
}

/**
 * Romanisation (checked tone ligature [陽入韻連字])
 */
@font-face {
  @include han-webfont;
  font-family: 'Romanization Sans';
  unicode-range:
    U+0307, U+030D, U+0358,
    U+F31B4-F31B7,
    U+F0061, U+F0065, U+F0069, U+F006F, U+F0075
  ;
}

