
%han-mono {
  font-family:
    $han-mono,
    han-typeface( 'Han Heiti', $_default-variant, $han-mono-zh ),
    monospace, monospace, sans-serif
  ;
}

%han-mono-hant {
  font-family:
    han-biaodian( Sans, $han-biaodian-hant ),
    $han-mono,
    'Zhuyin Heiti', 
    han-typeface( 'Han Heiti', $han-glyph-set-hant, $han-mono-zh ),
    monospace, monospace, sans-serif
  ;
}

%han-mono-hant-nu {
  font-family:
    $han-mono,
    han-typeface( 'Han Heiti', $han-glyph-set-hant, $han-mono-zh ),
    monospace, monospace, sans-serif
  ;
}

%han-mono-hans {
  font-family:
    han-biaodian( Sans, $han-biaodian-hans ),
    $han-mono,
    han-typeface( 'Han Heiti', $han-glyph-set-hans, $han-mono-zh ),
    monospace, monospace, sans-serif
  ;
}

%han-mono-hans-nu {
  font-family:
    $han-mono,
    han-typeface( 'Han Heiti', $han-glyph-set-hans, $han-mono-zh ),
    monospace, monospace, sans-serif
  ;
}

%han-mono-ja {
  font-family:
    'Yakumono Sans',
    $han-mono,
    monospace, monospace, sans-serif
  ;
}

%han-mono-ja-nu {
  font-family: $han-mono, monospace, monospace, sans-serif;
}

