
// *!
// * **WARNING**
// * Unless you knew well enough, *nothing* below should
// * be modified!
// *

$han-version: '@VERSION'

$han-webfont: url(#{$han-font-path}han.woff2?#{$han-version}) format('woff2'), url(#{$han-font-path}han.woff?#{$han-version}) format('woff'), url(#{$han-font-path}han.otf?#{$han-version}) format('opentype') !default
$han-space-webfont: url(#{$han-font-path}han-space.woff2?#{$han-version}) format('woff2'), url(#{$han-font-path}han-space.woff?#{$han-version}) format('woff'), url(#{$han-font-path}han-space.otf?#{$han-version}) format('opentype') !default

$_hanging-hant: 'h-char.bd-hangable:lang(zh), h-char.bd-hangable:lang(zh-Hant), h-char.bd-hangable:lang(zh-TW), h-char.bd-hangable:lang(zh-HK), '
$_hanging-hans: 'h-char.bd-hangable:lang(zh-Hans), h-char.bd-hangable:lang(zh-CN), '

$han-hanging-selector: if( $han-hanging-hant, $_hanging-hant, '' ) + if( $han-hanging-hans, $_hanging-hans, '' ) + if( $han-hanging-ja, 'h-char.bd-hangable:lang(ja)', '' )

