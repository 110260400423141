
%han-sans {
  font-family:
    $han-sans,
    han-typeface( 'Han Heiti', $_default-variant, $han-sans-zh ),
    sans-serif
  ;
}

%han-sans-hant {
  font-family:
    han-biaodian( Sans, $han-biaodian-hant ),
    //'Numeral LF Sans',
    $han-sans,
    'Zhuyin Heiti',
    han-typeface( 'Han Heiti', $han-glyph-set-hant, $han-sans-zh ),
    sans-serif
  ;
}

%han-sans-hant-nu {
  font-family:
    $han-sans,
    han-typeface( 'Han Heiti', $han-glyph-set-hant, $han-sans-zh ),
    sans-serif
  ;
}

%han-sans-hans {
  font-family:
    han-biaodian( Sans, $han-biaodian-hans ),
    //'Numeral LF Sans',
    $han-sans,
    han-typeface( 'Han Heiti', $han-glyph-set-hans, $han-sans-zh ),
    sans-serif
  ;
}

%han-sans-hans-nu {
  font-family:
    $han-sans,
    han-typeface( 'Han Heiti', $han-glyph-set-hans, $han-sans-zh ),
    sans-serif
  ;
}

%han-sans-ja {
  font-family:
    'Yakumono Sans',
    //'Numeral LF Sans',
    $han-sans,
    sans-serif
  ;
}

%han-sans-ja-nu {
  font-family: $han-sans, sans-serif;
}

/**
 * Sans Italic
 */
%han-sans-italic {
  font-family:
    'Latin Italic Sans',
    $han-sans,
    han-typeface( 'Han Heiti', $_default-variant, $han-sans-zh ),
    sans-serif
  ;
}

%han-sans-italic-hant {
  font-family:
    han-biaodian( Sans, $han-biaodian-hant ),
    'Latin Italic Sans',
    $han-sans,
    'Zhuyin Heiti',
    han-typeface( 'Han Heiti', $han-glyph-set-hant, $han-sans-zh ),
    sans-serif
  ;
}

%han-sans-italic-hant-nu {
  font-family:
    'Latin Italic Sans',
    $han-sans,
    han-typeface( 'Han Heiti', $han-glyph-set-hant, $han-sans-zh ),
    sans-serif
  ;
}

%han-sans-italic-hant-nu {
  font-family:
    'Latin Italic Sans',
    $han-sans,
    han-typeface( 'Han Heiti', $han-glyph-set-hant, $han-sans-zh ),
    sans-serif
  ;
}

%han-sans-italic-hans {
  font-family:
    han-biaodian( Sans, $han-biaodian-hans ),
    'Latin Italic Sans',
    $han-sans,
    han-typeface( 'Han Heiti', $han-glyph-set-hans, $han-sans-zh ),
    sans-serif
  ;
}

%han-sans-italic-hans-nu {
  font-family:
    'Latin Italic Sans',
    $han-sans,
    han-typeface( 'Han Heiti', $han-glyph-set-hans, $han-sans-zh ),
    sans-serif
  ;
}

%han-sans-italic-ja {
  font-family:
    'Yakumono Sans',
    'Latin Italic Sans',
    $han-sans,
    sans-serif
  ;
}

%han-sans-italic-ja-nu {
  font-family: 'Latin Italic Sans', $han-sans, sans-serif;
}

