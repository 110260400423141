
/**
 * The Four Typefaces: Heiti
 *  四大字體集・黑體
 */

// * 1. Recommended (推薦傳統字形，適用繁體漢字)
// * 2. CNS (台灣教育部國字標準字體［字形］)
// * 3. GB (中國國家標準)

// 1
@font-face {
  font-family: 'Han Heiti';
  src:
    local('Hiragino Sans GB'),

    local('Lantinghei TC Extralight'),
    local('Lantinghei SC Extralight'),
    local(FZLTXHB--B51-0),
    local(FZLTZHK--GBK1-0),

    local('Pingfang SC Light'),
    local('Pingfang TC Light'),
    local('Pingfang-SC-Light'),
    local('Pingfang-TC-Light'),
    local('Pingfang SC'),
    local('Pingfang TC'),

    local('Heiti SC Light'),
    local(STHeitiSC-Light),
    local('Heiti SC'),
    local('Heiti TC Light'),
    local(STHeitiTC-Light),
    local('Heiti TC'),

    local('Microsoft Yahei'),
    local('Microsoft Jhenghei'),

    local('Noto Sans CJK KR'),
    local('Noto Sans CJK JP'),
    local('Noto Sans CJK SC'),
    local('Noto Sans CJK TC'),
    local('Source Han Sans K'),
    local('Source Han Sans KR'),
    local('Source Han Sans JP'),
    local('Source Han Sans CN'),
    local('Source Han Sans HK'),
    local('Source Han Sans TW'),
    local('Source Han Sans TWHK'),
    local('Droid Sans Fallback')
  ;
}

@font-face {
  @include han-range-cjk;
  font-family: 'Han Heiti';
  src:
    local(YuGothic),
    local('Hiragino Kaku Gothic ProN'),
    local('Hiragino Kaku Gothic Pro')
  ;
}

// 2
@font-face {
  font-family: 'Han Heiti CNS';
  src:
    local('Pingfang TC Light'),
    local('Pingfang-TC-Light'),
    local('Pingfang TC'),
    local('Heiti TC Light'),
    local(STHeitiTC-Light),
    local('Heiti TC'),
    local('Lantinghei TC Extralight'),
    local(FZLTXHB--B51-0),
    local('Lantinghei TC'),

    local('Microsoft Jhenghei'),
    local('Microsoft Yahei'),

    local('Noto Sans CJK TC'),
    local('Source Han Sans TC'),
    local('Source Han Sans TW'),
    local('Source Han Sans TWHK'),
    local('Source Han Sans HK'),
    local('Droid Sans Fallback')
  ;
}

// 3
@font-face {
  font-family: 'Han Heiti GB';
  src:
    local('Hiragino Sans GB'),
    local('Pingfang SC Light'),
    local('Pingfang-SC-Light'),
    local('Pingfang SC'),
    local('Lantinghei SC Extralight'),
    local(FZLTXHK--GBK1-0),
    local('Lantinghei SC'),
    local('Heiti SC Light'),
    local(STHeitiSC-Light),
    local('Heiti SC'),

    local('Microsoft Yahei'),

    local('Noto Sans CJK SC'),
    local('Source Han Sans SC'),
    local('Source Han Sans CN'),
    local('Droid Sans Fallback')
  ;
}

/*
 * Bold
 */
@font-face {
  font-family: 'Han Heiti';
  font-weight: 600;
  src:
    local('Hiragino Sans GB W6'),
    local(HiraginoSansGB-W6),

    local('Lantinghei TC Demibold'),
    local('Lantinghei SC Demibold'),
    local(FZLTZHB--B51-0),
    local(FZLTZHK--GBK1-0),

    local('Pingfang-SC-Semibold'),
    local('Pingfang-TC-Semibold'),
    local('Heiti SC Medium'),
    local('STHeitiSC-Medium'),
    local('Heiti SC'),
    local('Heiti TC Medium'),
    local('STHeitiTC-Medium'),
    local('Heiti TC'),

    local('Microsoft Yahei Bold'),
    local('Microsoft Jhenghei Bold'),
    local(MicrosoftYahei-Bold),
    local(MicrosoftJhengHeiBold),
    local('Microsoft Yahei'),
    local('Microsoft Jhenghei'),

    local('Noto Sans CJK KR Bold'),
    local('Noto Sans CJK JP Bold'),
    local('Noto Sans CJK SC Bold'),
    local('Noto Sans CJK TC Bold'),
    local(NotoSansCJKkr-Bold),
    local(NotoSansCJKjp-Bold),
    local(NotoSansCJKsc-Bold),
    local(NotoSansCJKtc-Bold),
    local('Source Han Sans K Bold'),
    local(SourceHanSansK-Bold),
    local('Source Han Sans K'),
    local('Source Han Sans KR Bold'),
    local('Source Han Sans JP Bold'),
    local('Source Han Sans CN Bold'),
    local('Source Han Sans HK Bold'),
    local('Source Han Sans TW Bold'),
    local('Source Han Sans TWHK Bold'),
    local('SourceHanSansKR-Bold'),
    local('SourceHanSansJP-Bold'),
    local('SourceHanSansCN-Bold'),
    local('SourceHanSansHK-Bold'),
    local('SourceHanSansTW-Bold'),
    local('SourceHanSansTWHK-Bold'),
    local('Source Han Sans KR'),
    local('Source Han Sans CN'),
    local('Source Han Sans HK'),
    local('Source Han Sans TW'),
    local('Source Han Sans TWHK')
  ;
}

@font-face {
  @include han-range-cjk;
  font-family: 'Han Heiti';
  font-weight: 600;
  src:
    local('YuGothic Bold'),
    local('Hiragino Kaku Gothic ProN W6'),
    local('Hiragino Kaku Gothic Pro W6'),
    local(YuGo-Bold),
    local(HiraKakuProN-W6),
    local(HiraKakuPro-W6)
  ;
}

// 2
@font-face {
  font-family: 'Han Heiti CNS';
  font-weight: 600;
  src:
    local('Pingfang TC Semibold'),
    local('Pingfang-TC-Semibold'),
    local('Heiti TC Medium'),
    local('STHeitiTC-Medium'),
    local('Heiti TC'),
    local('Lantinghei TC Demibold'),
    local(FZLTXHB--B51-0),
    local('Microsoft Jhenghei Bold'),
    local(MicrosoftJhengHeiBold),
    local('Microsoft Jhenghei'),

    local('Microsoft Yahei Bold'),
    local(MicrosoftYahei-Bold),

    local('Noto Sans CJK TC Bold'),
    local(NotoSansCJKtc-Bold),
    local('Noto Sans CJK TC'),
    local('Source Han Sans TC Bold'),
    local('SourceHanSansTC-Bold'),
    local('Source Han Sans TC'),
    local('Source Han Sans TW Bold'),
    local('SourceHanSans-TW'),
    local('Source Han Sans TW'),
    local('Source Han Sans TWHK Bold'),
    local('SourceHanSans-TWHK'),
    local('Source Han Sans TWHK'),
    local('Source Han Sans HK'),
    local('SourceHanSans-HK'),
    local('Source Han Sans HK')
  ;
}

// 3
@font-face {
  font-family: 'Han Heiti GB';
  font-weight: 600;
  src:
    local('Hiragino Sans GB W6'),
    local(HiraginoSansGB-W6),

    local('Pingfang SC Semibold'),
    local('Pingfang-SC-Semibold'),
    local('Lantinghei SC Demibold'),
    local(FZLTZHK--GBK1-0),
    local('Heiti SC Medium'),
    local('STHeitiSC-Medium'),
    local('Heiti SC'),
    local('Microsoft Yahei Bold'),
    local(MicrosoftYahei-Bold),
    local('Microsoft Yahei'),

    local('Noto Sans CJK SC Bold'),
    local(NotoSansCJKsc-Bold),
    local('Noto Sans CJK SC'),
    local('Source Han Sans SC Bold'),
    local('SourceHanSansSC-Bold'),
    local('Source Han Sans CN Bold'),
    local('SourceHanSansCN-Bold'),
    local('Source Han Sans SC'),
    local('Source Han Sans CN')
  ;
}

