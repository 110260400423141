
/*  Base
   ------ */

// * 1. Different CJK fonts have different line heights,
// *    assign a unified value for consistency.
// * 2. Force WebKit to render texts subpixel-antialiasedly.
// *
#{$HAN-ROOT}
  // 1
  line-height: $HAN-LINE-HEIGHT
  // 2
  -webkit-font-smoothing: subpixel-antialiased

/*  Grouping content
   ------------------ */

/**
 * Paragraphs, lists, figures and blockquotes
 * (段落、清單、圖表、區塊引用)
 */

// * Position with `em`-unit (Hanzi), in pursuit of alignments.
// *
ol,
ul
  padding-left: $HAN-INDENT

figure,
blockquote
  margin-left: $HAN-INDENT
  margin-right: $HAN-INDENT

/**
 * Contact information
 * (聯絡資訊)
 */
address
  font-style: inherit

/**
 * Preformatted text
 * (格式預處理文字)
 */

// * Improve the readability
// *
pre
  // box-model
  overflow: auto
  // typography
  white-space: pre
  word-wrap: normal

/*  Text-level semantics & edits
   ------------------------------ */

/**
 * Hyperlinks
 * (超連結)
 */

// * Remove the underlines on hyperlinks for presentational
// * purpose.
// *
a
  text-decoration: inherit

/**
 * Emphases
 * (強調)
 */

// * 1. Pure CSS fallback.
// * 2. Remove fallback on browsers that support `text-emphasis`,
// *    so far, that's only WebKit.
// *
em
  &:lang(zh),
  &:lang(ja)
    +han-text-emphasis-internal
    // typography
    font-style: inherit
    // 1
    border-bottom: 2px dotted
    padding-bottom: .05em
    // 2
    border-bottom-width: -webkit-calc( 0px )
    padding-bottom: -webkit-calc( 0px )

em:lang(ja)
  +han-text-emphasis-internal( $HAN-TEXT-EMPHASIS-POSI-JA, $HAN-TEXT-EMPHASIS-MARK-JA )

/**
 * Definitions
 * (術語)
 */

// * Improvement for multiple weight fonts.
// * See: https://github.com/necolas/normalize.css/pull/342
// *

dfn:lang(zh),
dfn:lang(ja)
  font-weight: inherit
  font-style: inherit

dfn:lang(zh),
dfn:lang(ja)
  font-weight: bolder

/**
 * Cites and quotes
 * (來源、引用)
 */
cite
  &:lang(zh),
  &:lang(ja)
    font-style: inherit

q
  quotes: '\201c' '\201d' '\2018' '\2019' '\201c' '\201d' '\2018' '\2019' '\201c' '\201d' '\2018' '\2019'

  &:lang(zh)
    quotes: '\300c' '\300d' '\300e' '\300f' '\300c' '\300d' '\300e' '\300f' '\300c' '\300d' '\300e' '\300f'

  &:lang(zh-CN),
  &:lang(en)
    quotes: '\201c' '\201d' '\2018' '\2019' '\201c' '\201d' '\2018' '\2019' '\201c' '\201d' '\2018' '\2019'

  &:lang(en-GB)
    quotes: '\2018' '\2019' '\201c' '\201d' '\2018' '\2019' '\201c' '\201d' '\2018' '\2019' '\201c' '\201d'

  &:before
    content: open-quote

  &:after
    content: close-quote

  &:lang(ja):before,
  &:lang(ja):after
    content: none

/**
 * Code, user input, computer output and preformatted text
 * (代碼、輸入鍵、計算機輸出示例、格式預處理文字)
 */

// * Correct odd `em`-unit font size rendering in all browsers
// * (still iffy for Chinese in WebKit).
// *
code,
kbd,
samp,
pre
  font-family: monospace, monospace, sans-serif

/**
 * Alternative voices and variables
 * (變音文字、變數)
 */
i,
var
  &:lang(zh),
  &:lang(ja)
    font-family: cursive, serif
    font-style: inherit

/**
 * Annotations, inaccurate text, insertion and deletion
 * (註記、訛訊、增訂、刪訂)
 */

// * Semantic presentation for two adjacent underlined
// * text runs.
// *
%han-adjacent-deco-line
  margin-left: .125em

u,
ins
  & + u,
  & + ins
    @extend %han-adjacent-deco-line
s,
del
  & + s,
  & + del
    @extend %han-adjacent-deco-line

u,
ins
  // box
  padding-bottom: 0.05em
  // typography
  border-bottom: 1px solid
  text-decoration: none

/**
 * Ruby annotations
 * (行間注)
 */
ruby
  rtc rt
    // box-model
    display: inline
    // typography
    font-size: inherit
  &.zhuyin,
  &.mps
    // box-model
    display: ruby
    // typography
    -webkit-ruby-position: inter-character
    ruby-position:         inter-character

    > rt
      // box-model
      +han-scale( $HAN-ZHUYIN-SIZE/.5, 'left center' )
      // typography
      font-size: .5em
    > rt:empty
      display: none

