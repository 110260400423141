
$_default-variant: if( $han-default-variant == hans, $han-glyph-set-hans, $han-glyph-set-hant )

// **
// * Functions
// *
@function han-biaodian( $generic: Sans, $set: Pro )
  @if ( $set == simp )
    @return 'Biaodian ' + $generic
  @return 'Biaodian Pro ' + if( $set == Pro, $generic, $generic + ' ' + $set )

@function han-typeface( $typeface: 'Han Heiti', $set: default, $pre: null )
  $typeface: if( $set == default, $typeface, $typeface + ' ' + $set )
  @return if( $pre == null or $pre == '', $typeface, ($pre, $typeface))

@import extend/sans
@import extend/serif
@import extend/cursive
@import extend/mono

%han-ligature
  -moz-font-feature-settings: 'liga'
  -ms-font-feature-settings: 'liga'
  -webkit-font-feature-settings: 'liga'
  font-feature-settings: 'liga'

// We do not need the `locl` property of OpenType typefaces,
// for we have better fallback mechanism in Han.css.
// (*Internal use with Source Han Sans only)
%han-no-locl
  -moz-font-feature-settings: 'liga=1, locl=0'
  -ms-font-feature-settings: 'liga', 'locl' 0
  -webkit-font-feature-settings: 'liga', 'locl' 0
  font-feature-settings: 'liga', 'locl' 0

